<template>
  <div
    class="searchResult"
    v-watermark
  >
    <commonNavBar :title="'框架合同计费查询'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay
      :show="loadingShow"
      class-name="myLoading"
    >
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div class="searchBox">
      <form action="/">
        <van-search
          v-model="value"
          show-action
          left-icon=""
          :placeholder="placeholder"
          @search="onSearch"
          @input="onInput"
          autofocus
        >
          <template #action>
            <div @click="searchBtn">搜索</div>
          </template>
          <template #label>
            <van-dropdown-menu active-color="#1989fa">
              <van-dropdown-item
                v-model="type"
                :options="option1"
                @change="typeChange"
                :disabled="loadingShow"
              />
            </van-dropdown-menu>
          </template>
        </van-search>
      </form>
      <div class="siftDp">
        <van-dropdown-menu
          active-color="#3ea7f0"
          :close-on-click-outside="flag"
        >
          <van-dropdown-item
            :title="addTitle"
            ref="item"
            @open="addOpened"
            @opened="addOpened"
            :disabled="loadingShow"
          >
            <van-tree-select
              :items="items"
              :active-id.sync="activeIds"
              :main-active-index.sync="activeIndex"
              @click-item="itemClickHandler"
              @click-nav="navClick"
            />
            <div class="myButton">
              <van-button
                @click="(activeIds = []), (activeIndex = items.length)"
                color="#969799"
                block
                size="small"
              >
                重置
              </van-button>
              <van-button
                type="info"
                block
                size="small"
                @click="confirmClick"
              >
                确认
              </van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            v-model="sequence"
            :options="sequenceOption"
            :title="sequenceTitle"
            @change="sequenceChange"
            :disabled="loadingShow"
            @open="sequenceOpened"
          />
        </van-dropdown-menu>
      </div>
      <div
        class="searchBar"
        v-if="companyList.length"
      >
        <span class="label">搜索结果:共{{ total }}条</span>
      </div>
      <!-- 结果列表 -->
      <div
        class="listBox"
        v-if="companyList.length"
        @scroll="onScroll"
        ref="listBox"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :error.sync="error"
          error-text="请求失败,点击重新加载!"
        >
          <!-- 卡片 -->
          <div
            class="myCard"
            v-for="(item, index) in companyList"
            :key="index"
            @click="$router.push({
              name: 'standardAddressInfo',
              params: {
                Refresh: true,
                id: item.id
              }
            })"
          >
            <div class="name">
              <span class="label">{{ item.internetCompany || "--" }}</span>
              <van-tag
                type="danger"
                v-if="item.status === 1"
              >睡眠</van-tag>
              <van-tag
                type="success"
                v-if="item.status === 0"
              >激活</van-tag>
            </div>
            <p>单位编号:{{ item.companyCode || "--" }}</p>
            <p>委托单位:{{ item.entrustCompany || "--" }}</p>
            <p>更新日期:{{ item.updateDatetime | openYmdFormat }}</p>
          </div>
        </van-list>
      </div>
      <!-- 空结果 -->
      <div
        class="empty"
        v-else
      >
        <van-empty
          image="search"
          description="没有符合条件的数据"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getDistrictList } from '@/api/system'
import { getframeworkList } from '@/api/standard'
let timeId = null
export default {
  name: 'searchResult',
  data() {
    return {
      option1: [
        { text: '单位名', value: 0 },
        { text: '单位编号', value: 1 }
      ],
      value: '',
      type: 0,
      flag: true,
      companyList: [],
      loading: false,
      finished: false,
      loadingShow: false,
      top: 0,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      error: false,
      items: [],
      activeIds: [],
      activeIndex: null,
      confirmActiveIds: [],
      confirmActiveIndex: null,
      addTitle: '区域',
      sequenceOption: [
        { text: '默认排序', value: 'default' },
        { text: '更新时间由近及远', value: 'desc' },
        { text: '更新时间由远及近', value: 'asc' }
      ],
      sequence: '',
      sequenceTitle: '默认排序'
    }
  },
  // 激活页面
  activated() {
    if (this.$refs.listBox) {
      this.$refs.listBox.scrollTop = this.top
    }
  },
  // 路由进入页面
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/workTable/standardAddressInfo')) {
      next(vm => {
        if (!vm.companyList.length) {
          vm.getDistrictList()
          vm.onLoad()
        }
      })
    } else {
      next(vm => {
        Object.assign(vm.$data, vm.$options.data.call(vm))
        vm.getDistrictList()
        vm.onLoad()
      })
    }
  },

  computed: {
    placeholder() {
      if (this.type === 0) {
        return '请输入联网单位名称'
      } else if (this.type === 1) {
        return '请输入联网单位编号'
      } else {
        return '请输入联网单位名称'
      }
    }
  },
  watch: {
    confirmActiveIds: {
      handler: function (val, oldVal) {
        if (val.length === 1 && val.includes('0')) {
          this.addTitle = this.items[this.confirmActiveIndex].text
        } else if (val.length > 1) {
          this.addTitle = '区域(多选)'
        } else if (!val.length) {
          this.addTitle = '区域'
        } else if (val.length === 1 && !val.includes('0')) {
          this.addTitle = this.items[this.confirmActiveIndex].children[
            this.items[this.confirmActiveIndex].children.findIndex(item => {
              return item.id === val[0]
            })
          ].text
        } else {
          this.addTitle = '区域'
        }
      },
      deep: true
    },
    sequence: {
      handler: function (val, oldVal) {
        if (val === 'default') {
          this.sequenceTitle = '默认排序'
        } else {
          this.sequenceTitle = this.sequenceOption.filter(item => item.value === val)[0]?.text || '默认排序'
        }
      }
    }
  },
  methods: {
    // 搜索类型点击
    async typeChange(val) {
      if (this.value) {
        this.value = ''
        this.pageNum = 1
        this.error = false
        this.top = 0
        this.companyList = []
        if (this.$refs.listBox) {
          this.$refs.listBox.scrollTop = 0
        }
        this.finished = false
        this.onLoad()
      }
    },
    // 搜索
    onSearch(val) {
      if (val) {
        this.pageNum = 1
        this.error = false
        this.top = 0
        this.companyList = []
        if (this.$refs.listBox) {
          this.$refs.listBox.scrollTop = 0
        }
        this.finished = false
        this.onLoad()
      }
    },
    // 输入搜索框内容
    onInput(val) {
      if (val) {
        this.suggestionList = []
        clearTimeout(timeId)
        timeId = setTimeout(async () => {
          this.pageNum = 1
          this.error = false
          this.top = 0
          this.companyList = []
          if (this.$refs.listBox) {
            this.$refs.listBox.scrollTop = 0
          }
          this.finished = false
          this.onLoad()
        }, 300)
      } else {
        this.suggestionList = []
      }
    },
    // 点击搜索按钮
    searchBtn() {
      if (this.value && !this.loadingShow) {
        this.onSearch(this.value)
      }
    },
    // 加载列表
    async onLoad() {
      if (this.pageNum === 1) {
        this.loadingShow = true
      }
      try {
        const { data } = await getframeworkList({
          type: this.type,
          frameworkId: this.$route.params.id,
          'page.current': this.pageNum,
          'page.size': this.pageSize,
          'sort.key': '0',
          'sort.value':
            this.sequence === '' || this.sequence === 'default'
              ? 'desc'
              : this.sequence,
          search: this.value || null,
          district: this.confirmActiveIds.length
            ? this.confirmActiveIds[0] === '0'
              ? this.items[this.confirmActiveIndex].id
              : this.confirmActiveIds.toString()
            : null,
          fuzzy: 1
        })
        this.total = data.data.total
        this.pageNum = this.pageNum + 1
        // 追加数据
        const results = data.data.records
        this.companyList.push(...results)
        this.loading = false
        if (!results.length) {
          this.finished = true
        }
        if (this.loadingShow) {
          this.loadingShow = false
        }
      } catch (error) {
        if (error.message === '取消请求') {
          this.error = false
          return
        }
        // 加载状态结束
        this.loading = false
        this.error = true
        if (this.loadingShow) {
          this.loadingShow = false
        }
        if (error.message.includes('timeout')) {
          if (this.$route.path === '/workTable/constructionResult') {
            this.$toast.fail({
              message: '网络不好，请稍后再试!',
              duration: 500
            })
          }
        }
      }
    },
    onScroll(e) {
      this.top = e.target.scrollTop
    },
    // 获取区域树形数据
    async getDistrictList() {
      const {
        data: { data }
      } = await getDistrictList()
      this.items = data.map(item => {
        item.children.unshift({
          text: '不限',
          id: '0'
        })
        return item
      })
    },
    // 地区选择点击
    itemClickHandler(data) {
      if (data.id === '0') {
        this.activeIds = ['0']
      } else if (this.activeIds.includes('0')) {
        this.activeIds.splice(
          this.activeIds.findIndex(item => item === '0'),
          1
        )
      }
    },
    // 左侧点击
    navClick(index) {
      if (index !== this.items.length) {
        this.activeIds = ['0']
      }
    },
    // 区域选择确认
    confirmClick() {
      this.confirmActiveIndex = this.activeIndex
      this.confirmActiveIds = [...this.activeIds]
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.companyList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
      this.$refs.item.toggle(false)
    },
    // 打开区域下拉框
    addOpened() {
      this.activeIndex = this.confirmActiveIndex
      this.activeIds = [...this.confirmActiveIds]
      if (
        this.activeIndex === null ||
        !this.activeIds.length ||
        this.activeIndex === this.items.length
      ) {
        this.activeIndex = 0
        this.activeIds = ['0']
      }
    },
    // 打开状态下来
    statusOpened() {
      if (this.status === '') {
        this.status = 'all'
      }
    },
    // 打开排序
    sequenceOpened() {
      if (this.sequence === '') {
        this.sequence = 'default'
      }
    },
    // 排序变化
    sequenceChange() {
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.companyList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
    }
  }
}
</script>

<style lang="scss" scoped>
.searchResult {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #eeeeee;

  ::v-deep {
    .myLoading {
      top: 148px;
      bottom: 0;
      height: auto;

      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .van-calendar__popup.van-popup--bottom,
    .van-calendar__popup.van-popup--top {
      height: 61% !important;
    }

    .searchBox {
      height: calc(100% - 46px);
      overflow: auto;

      .van-search__content {
        padding-left: 0;
      }

      .van-search__label {
        .van-dropdown-menu {
          height: 100%;
          width: 100%;
          background-color: transparent;
          overflow: hidden;

          .van-dropdown-menu__bar {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            background-color: transparent;
            box-shadow: none;

            .van-ellipsis {
              padding-right: 5px;
              font-size: 14px;
            }
          }

          .van-dropdown-menu__title::after {
            right: 1px;
          }
        }

        .van-dropdown-item {
          .van-dropdown-item__content {
            .van-dropdown-item__option {
              .van-cell__title {
                padding-left: 25px;
                font-size: 14px;
              }
            }
          }
        }

        .van-dropdown-menu__item--disabled {
          .van-dropdown-menu__title {
            color: #323233;
          }
        }
      }

      .siftDp {
        overflow: hidden;

        .van-dropdown-menu {
          >div {
            &:nth-child(5) {
              .van-dropdown-item__content {

                .more {

                  .moreLabel {
                    font-size: 16px;
                    padding-left: 16px;
                  }

                  .itemValue {
                    .van-radio--horizontal {
                      width: auto;
                      margin-bottom: 0;
                    }
                  }
                }

              }
            }
          }

          .van-dropdown-menu__bar {
            box-shadow: none;

            .van-dropdown-menu__item {
              &:nth-child(5) {
                flex: none;

                .noIcon {
                  &::after {
                    border: none;
                  }
                }

                .mySequence {
                  color: #3ea7f0;
                }
              }
            }
          }
        }

        .van-radio--horizontal {
          margin-right: 0;
          width: 25%;
          margin-bottom: 10px;
        }

        .van-checkbox--horizontal {
          margin-right: 0;
          margin-bottom: 10px;
        }

        .myButton {
          padding: 5px 16px;
          display: flex;

          .van-button--small {
            margin: 0 5px;
          }
        }

        .van-sidebar-item--select::before {
          background-color: #3ea7f0;
        }

        .van-tree-select__item--active {
          color: #3ea7f0;
        }
      }

      .searchBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 24px;
        padding-bottom: 5px;
        background-color: #eeeeee;

        .label {
          font-size: 12px;
          color: #aaaaaa;
          padding-top: 5px;
        }

        .myIcon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .empty {
      height: calc(100% - 54px - 24px - 48px);
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .listBox {
      height: calc(100% - 24px - 54px - 48px);
      overflow: auto;
      padding: 0 7px;

      // padding-top: 10px;
      // padding: 9px 0;
      // padding-bottom: 0px;
      .myCard {
        // margin-bottom: 9px;
        // background-color: white;
        margin-bottom: 10px;
        background-color: white;
        border-radius: 5px;
        padding: 5px;

        .name {
          font-size: 0;
          margin-bottom: 5px;

          .label {
            font-size: 14px;
            margin-right: 10px;
            font-weight: 700;
          }
        }

        >p {
          font-size: 12px;
          color: #969799;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
